import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import numeral from 'numeral';
import cloneDeep from 'lodash/cloneDeep';
import head from 'lodash/head';
import map from 'lodash/map';
import _range from 'lodash/range';
import remove from 'lodash/remove';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiTooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import {
  Bar,
  BarChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  today,
  yesterday,
  thisWeek,
  lastWeek,
  thisMonth,
  lastMonth,
  generateTicks,
} from '../../../../helpers/date';
import { displaykW } from '../../../../helpers/display-energy';
import { SOLAR_APP_ID } from '../../../../store/solar';
import WebAPIClient, { errorResponseToastr } from '../../../../api';
import WidgetLoader from '../../../../components/Loaders/WidgetLoader';
import ChartTooltip from './Tooltip';
import SelectRange from '../../../../components/Selectors/SelectRange';

const RANGES = [
  {
    key: 0,
    label: 'Today',
    func: today,
  },
  {
    key: 1,
    label: 'Yesterday',
    func: yesterday,
  },
  {
    key: 2,
    label: 'This Week',
    func: thisWeek,
  },
  {
    key: 3,
    label: 'Last Week',
    func: lastWeek,
  },
  {
    key: 4,
    label: 'This Month',
    func: thisMonth,
  },
  { key: 5, label: 'Last Month', func: lastMonth },
  { key: 6, label: 'Custom Range' },
];

const compileChartData = (meterId, range, actual, backfilled) => {
  let _actual = cloneDeep(actual);
  let _backfilled = cloneDeep(backfilled);

  let timeseries = _range(range.start.unix(), range.end.unix() + 1, 900);
  timeseries = map(timeseries, (timestamp) => {
    let row = { timestamp };
    const actualRow = head(
      remove(_actual, (dataPoint) => {
        return dataPoint.timestamp === timestamp;
      })
    );
    const backfilledRow = head(
      remove(_backfilled, (dataPoint) => {
        return dataPoint.timestamp === timestamp;
      })
    );

    if (actualRow && 'value' in actualRow) {
      row[meterId] = actualRow.value;
    }
    if (backfilledRow && 'value' in backfilledRow) {
      row['backfilled'] = backfilledRow.value;
    }
    return row;
  });
  return timeseries;
};

const toUnix = (value) => {
  if (typeof value === 'object' && 'unix' in value) {
    return value.unix();
  }
  return value;
};

export default function ProductionData(props) {
  const {
    meter,
    actualData,
    setActualData,
    timezone,
    backfilledData,
    setBackfilledData,
    backfillStart,
    backfillEnd,
  } = props;
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [productionSum, setProductionSum] = useState(0);

  useEffect(() => {
    if (
      meter?.meter_id &&
      (actualData.length > 0 || backfilledData.length > 0)
    ) {
      setChartData(
        compileChartData(meter?.meter_id, range, actualData, backfilledData)
      );
    }
  }, [meter?.meter_id, range, actualData, backfilledData]);

  useEffect(() => {
    const sum = chartData.reduce((acc, curr) => {
      if (curr[meter?.meter_id]) {
        return acc + curr[meter?.meter_id];
      }
      return acc;
    }, 0);
    setProductionSum(sum);
  }, [chartData, meter?.meter_id]);

  useEffect(() => {
    if (timezone.zone) {
      setRange((r) => {
        if (r.key === 6) {
          return { ...r };
        } else {
          return { ...r, ...r.func(timezone.zone) };
        }
      });
    }
  }, [timezone.zone]);

  const fetchChartData = async () => {
    setLoading(true);
    try {
      const records = await new WebAPIClient(SOLAR_APP_ID).GET(
        `/resource/timestream/${
          meter.org_id
        }/${range.start.valueOf()}/${range.end.valueOf()}`,
        { deviceId: meter.meter_id }
      );
      setActualData(records);
    } catch (err) {
      console.error(err);
      errorResponseToastr(err);
    } finally {
      setLoading(false);
    }
  };

  const clearData = () => {
    setActualData([]);
    setBackfilledData([]);
    setChartData([]);
  };

  const handleSelectRange = (newRange) => {
    clearData();
    setRange(newRange);
  };

  let ticks = [];
  if (range) {
    ticks = generateTicks(range);
  }
  return (
    <Card raised sx={{ py: 0.5, px: 1 }}>
      <CardHeader
        sx={{ '& .MuiCardHeader-content': { display: 'flex' }, py: 1, mr: 2 }}
        title='Production Data'
        action={
          <Stack
            direction='row'
            spacing={2}
            justifyContent='flex-end'
            alignItems='center'
            divider={<Divider orientation='vertical' flexItem sx={{ m: 1 }} />}>
            <TextField
              variant='standard'
              label='Total Production'
              value={displaykW(productionSum / 4, true)}
              sx={{
                width: '7rem',
                '& .MuiInputLabel-root': {
                  right: 0,
                  textAlign: 'center',
                },
                '& .MuiInputLabel-shrink': {
                  width: '150px',
                },
              }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ style: { textAlign: 'center' } }}
            />
            <SelectRange
              ranges={RANGES}
              range={range}
              defaultRange={RANGES[5]}
              setRange={handleSelectRange}
              timezone={timezone}
            />
            <MuiTooltip title='fetch data' placement='top'>
              <span>
                <IconButton onClick={fetchChartData} disabled={!meter}>
                  <FontAwesomeIcon icon={['fal', 'cloud-download']} />
                </IconButton>
              </span>
            </MuiTooltip>
            <MuiTooltip title='reset chart' placement='top'>
              <span>
                <IconButton
                  onClick={clearData}
                  disabled={chartData.length === 0}>
                  <FontAwesomeIcon icon={['fal', 'undo']} />
                </IconButton>
              </span>
            </MuiTooltip>
          </Stack>
        }
      />
      <WidgetLoader loading={loading} />
      <ResponsiveContainer
        key='production-data-container'
        width='100%'
        height={350}
        style={{ marginBottom: 2 }}>
        <BarChart
          width='100%'
          height={350}
          data={chartData}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}>
          <YAxis
            width={75}
            domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
            tickFormatter={(val, _axis) => {
              if (val <= 0) return '';
              return (
                numeral(Math.round(val).toPrecision(4)).format('0,0.[000]') +
                ' kW'
              );
            }}
          />
          <XAxis
            type='number'
            dataKey='timestamp'
            tickMargin={10}
            domain={[range?.start.unix(), range?.end.unix()]}
            ticks={ticks}
            tickFormatter={(unixTime) => {
              let format = 'MMM Do h:mm A';
              if (range.end.unix() - range.start.unix() > 90000) {
                format = 'MMM Do';
              }
              return dayjs.unix(unixTime).tz(timezone.zone).format(format);
            }}
          />
          <Tooltip
            content={<ChartTooltip meter={meter} timezone={timezone} />}
          />
          {backfillStart && (
            <ReferenceLine
              x={toUnix(backfillStart)}
              stroke={theme.palette.secondary.main}
              label={
                <Label position='insideTopRight' offset={20}>
                  backfill start
                </Label>
              }
            />
          )}
          {backfillEnd && (
            <ReferenceLine
              x={toUnix(backfillEnd)}
              stroke={theme.palette.secondary.main}
              label={
                <Label position='insideTopLeft' offset={20}>
                  backfill end
                </Label>
              }
            />
          )}
          <Bar
            barSize={10}
            key='bar-backfilledData'
            type='monotone'
            dataKey='backfilled'
            fill={theme.palette.secondary.main}
          />
          <Bar
            barSize={10}
            key={`bar-${meter?.meter_id}`}
            type='monotone'
            dataKey={meter?.meter_id}
            fill={theme.palette.primary.main}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}
