import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { find, isEmpty, map } from 'lodash';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import WebAPIClient from '../../../../../api';
import { DEMAND_APP_ID } from '../../../../../store/demand';
import { current, getLocalTimzone } from '../../../../../helpers/date';
import WeatherChartToolbar from './Toolbar';
import WeatherChart, { METRICS } from './Chart';

const compileWeatherData = (rawData) =>
  map(rawData, (dataPoint) => {
    return {
      ...dataPoint,
      timestamp: Number(dataPoint.timestamp),
    };
  });

const ALL_METRICS = Object.keys(METRICS);
function WeatherChartContainer() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const rtos = useSelector((state) => state.demand.rtos);

  const [loading, setLoading] = useState(false);
  const [rto, setRto] = useState({});
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([
    ALL_METRICS[0],
    ALL_METRICS[1],
    ALL_METRICS[3],
    ALL_METRICS[4],
    ALL_METRICS[9],
  ]);

  const [timezone, setTimezone] = useState(getLocalTimzone());
  const [range, setRange] = useState({
    key: 0,
    label: 'Current',
    ...current(timezone.zone),
  });

  useEffect(() => {
    setRto(find(rtos, { rto_id: 'PJM' }));
  }, [rtos]);

  useEffect(() => {
    if (!loading && !isEmpty(rawData)) {
      const compiledData = compileWeatherData(rawData);
      setChartData(compiledData);
    }
    /* eslint-disable-next-line */
  }, [rawData, range, loading]);

  const fetchChartData = (_range = range) => {
    setLoading(true);
    new WebAPIClient(DEMAND_APP_ID)
      .GET(`/resource/weather/${rto.rto_id}`, {
        start: _range.start.unix(),
        end: _range.end.unix(),
      })
      .then((payload) => {
        setRawData(payload);
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <WeatherChartToolbar
        selectedMetrics={selectedMetrics}
        setSelectedMetrics={setSelectedMetrics}
        range={range}
        setRange={setRange}
        timezone={timezone}
        setTimezone={setTimezone}
        fetchChartData={fetchChartData}
      />
      <Grid
        item
        xs={12}
        mt={isMobile ? 1 : 0}
        mb={2}
        ml={isMobile ? 0 : 1}
        mx={1}
        display='flex'
        justifyContent='center'>
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[8],
            p: 1,
            width: '100%',
            maxWidth: 1000,
          }}>
          <WeatherChart
            chartData={chartData}
            selectedMetrics={selectedMetrics}
            range={range}
            timezone={timezone}
            loading={loading}
          />
        </Box>
      </Grid>
    </>
  );
}

WeatherChartContainer.propTypes = {};

export default WeatherChartContainer;
