import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import map from 'lodash/map';

import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { DEMAND_APP_ID } from '../../store/demand';
import { SOLAR_APP_ID } from '../../store/solar';
import { StyledTd } from '../../components/charts/tooltips/BaseTooltip';
import { isLineSelected } from './Chart';

const StyledTh = styled('th')(({ align = 'left' }) => {
  return { fontWeight: 'normal', textAlign: align };
});

function Tooltip({
  active,
  payload,
  label,
  timezone,
  appId,
  selectedResourceId,
  selectedIpAddress,
  selectedUserAgent,
  userAgents,
  showUserAgentDetails,
}) {
  const sites = useSelector((state) => {
    if (appId === SOLAR_APP_ID) {
      return state.solar.sites;
    }
    if (appId === DEMAND_APP_ID) {
      return state.demand.sites;
    }
    return [];
  });
  const orgs = useSelector((state) => {
    if (appId === SOLAR_APP_ID) {
      return state.solar.organizations;
    }
    if (appId === DEMAND_APP_ID) {
      return state.demand.organizations;
    }
    return [];
  });

  const getProperties = (resource, userAgents, showUserAgentDetails) => {
    const dataKey = resource?.dataKey;
    let type = '';
    let name = '';
    let resourceId = '';
    let ipAddress = '';
    let userAgent = '';

    if (dataKey?.startsWith('site')) {
      type = 'Site';
      const [siteId, _ipAddress, _userAgent] = dataKey.split('=');
      const site = sites.find((s) => s.site_id === siteId);
      name = site?.name;
      resourceId = siteId;
      ipAddress = _ipAddress;
      userAgent = _userAgent;
    } else if (dataKey?.startsWith('org')) {
      const [orgId, _ipAddress, _userAgent] = dataKey.split('=');
      const org = orgs.find((o) => o.org_id === orgId);
      type = 'Org';
      name = org?.name;
      resourceId = orgId;
      ipAddress = _ipAddress;
      userAgent = _userAgent;
    } else if (dataKey) {
      const [_resourceId, _ipAddress, _userAgent] = dataKey.split('=');
      type = '';
      name = resourceId;
      resourceId = _resourceId;
      ipAddress = _ipAddress;
      userAgent = _userAgent;
    }

    if (!showUserAgentDetails) {
      userAgent = userAgents.findIndex((ua) => ua === userAgent);
      userAgent = `User Agent ${userAgent + 1}`;
    }
    return [type, name, resourceId, ipAddress, userAgent];
  };

  if (active && payload && payload.length) {
    return (
      <Card
        raised
        sx={{
          // width: '26rem',
          p: 1,
        }}>
        {label && (
          <Typography variant='body1' align='center'>
            {dayjs.unix(label).tz(timezone.zone).format('MMM Do h:mm A')}
          </Typography>
        )}
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <StyledTh>
                <Typography variant='body1'>Type</Typography>
              </StyledTh>
              <StyledTh align='left'>
                <Typography variant='body1'>Name</Typography>
              </StyledTh>
              <StyledTh align='left'>
                <Typography variant='body1'>IP Address</Typography>
              </StyledTh>
              <StyledTh align='left'>
                <Typography variant='body1'>User Agent</Typography>
              </StyledTh>
              <StyledTh align='right'>
                <Typography variant='body1'>Requests</Typography>
              </StyledTh>
            </tr>
          </thead>
          <tbody>
            {map(payload, (resource) => {
              const [type, name, resourceId, ipAddress, userAgent] =
                getProperties(resource, userAgents, showUserAgentDetails);
              const lineSelected = isLineSelected(
                selectedResourceId,
                selectedIpAddress,
                selectedUserAgent,
                resourceId,
                ipAddress,
                userAgent
              );

              if (!lineSelected) {
                return null;
              }
              return (
                <tr key={resource?.dataKey}>
                  <StyledTd color={resource.color}>{type}</StyledTd>
                  <StyledTd color={resource.color}>{name}</StyledTd>
                  <StyledTd color={resource.color}>{ipAddress}</StyledTd>
                  <StyledTd color={resource.color}>{userAgent}</StyledTd>
                  <StyledTd color={resource.color} align='right'>
                    {resource?.value}
                  </StyledTd>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }

  return null;
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  appId: PropTypes.string,
  selectedResourceId: PropTypes.string,
  selectedIpAddress: PropTypes.string,
};

export default Tooltip;
