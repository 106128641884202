import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import dayjs from 'dayjs';

import { displaykW } from '../../../../helpers/display-energy';
import BaseTooltip from '../../../../components/charts/tooltips/BaseTooltip';

function Tooltip({ active, payload, label, timezone, meter, inverters }) {
  const resourceName = (resource) => {
    if (resource.dataKey.startsWith('meter')) {
      return meter.name;
    } else if (resource.dataKey.startsWith('inverter')) {
      const inverter = inverters.find(
        (inv) => inv.inverter_id === resource.dataKey
      );
      return inverter?.name;
    } else {
      return startCase(resource.dataKey);
    }
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={dayjs.unix(label).tz(timezone.zone).format('MMM Do h:mm A')}
      getName={resourceName}
      getValue={(resource) => displaykW(resource?.value)}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  meter: PropTypes.object,
  inverters: PropTypes.array,
};

export default Tooltip;
