import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import BaseTooltip from '../../../../../components/charts/tooltips/BaseTooltip';

function Tooltip(props) {
  const { active, payload, label, timezone, metrics } = props;

  const renderValue = (resource) => {
    const metric = metrics[resource.dataKey];
    return `${Math.round(resource.value * 100) / 100} ${metric.unit}`;
  };

  return (
    <BaseTooltip
      active={active}
      payload={payload}
      label={dayjs.unix(label).tz(timezone.zone).format('MMM Do h:mm A')}
      getName={(resource) => startCase(resource.dataKey)}
      getValue={renderValue}
      width='20rem'
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  metrics: PropTypes.object,
};

export default Tooltip;
