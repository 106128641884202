import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiTooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

import {
  getLocalTimzone,
  lastMonth,
  lastWeek,
  thisMonth,
  thisWeek,
} from '../../helpers/date';
import { getMetricNames } from './metrics';
import CustomTooltip from './Tooltip';
import SelectMetrics from '../Selectors/SelectMetrics';
import WidgetLoader from '../Loaders/WidgetLoader';
import SelectRange from '../Selectors/SelectRange';

const RANGES = [
  {
    key: 0,
    label: 'This Week',
    func: thisWeek,
  },
  {
    key: 1,
    label: 'Last Week',
    func: lastWeek,
  },
  {
    key: 2,
    label: 'This Month',
    func: thisMonth,
  },
  {
    key: 3,
    label: 'Last Month',
    func: lastMonth,
  },
  { key: 4, label: 'Custom Range' },
];

const findMetricSettings = (metrics, metric) => {
  let metricSettings = {};
  for (const group of Object.keys(metrics)) {
    for (const option of metrics[group]) {
      if (option.metric === metric) {
        metricSettings = option;
      }
    }
  }
  return metricSettings;
};

const prepChartData = (data) => {
  return map(data, (values, timestamp) => {
    const metrics = reduce(
      values,
      (acc, value) => ({ ...acc, [value.metric]: Number(value.value) }),
      {}
    );

    return {
      timestamp: Number(timestamp),
      ...metrics,
    };
  });
};

function KPITrendChart(props) {
  const { allMetrics, fetchData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);
  const [timezone] = useState(getLocalTimzone());
  const [chartData, setChartData] = useState([]);
  const [range, setRange] = useState({
    key: 4,
    label: 'Custom Range',
    start: dayjs().subtract(1, 'days').subtract(2, 'month').startOf('day'),
    end: dayjs().subtract(1, 'days').endOf('day'),
  });
  const [metrics, setMetrics] = useState([
    'meters',
    'monthly_active_users',
    'alarms',
  ]);

  const fetchChartData = async (range) => {
    if (!metrics.length) return;

    setLoading(true);
    const payload = await fetchData(
      range.start.valueOf(),
      range.end.valueOf(),
      metrics
    );
    setChartData(prepChartData(payload));
    setLoading(false);
  };

  useEffect(() => {
    if (!loading && !chartData.length) {
      fetchChartData(range);
    }
    /* eslint-disable-next-line */
  }, [range]);

  const handleRangeSelect = (newRange) => {
    setRange(newRange);
    fetchChartData(newRange);
  };

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        borderRadius: 1,
        boxShadow: (theme) => theme.shadows[10],
        p: 1,
        width: '100%',
        maxWidth: 1000,
      }}>
      <Stack
        direction='row'
        spacing={2}
        mr={2}
        justifyContent='flex-end'
        alignItems='center'
        divider={<Divider orientation='vertical' flexItem sx={{ m: 1 }} />}>
        <SelectMetrics
          metrics={getMetricNames(allMetrics)}
          selectedMetrics={metrics}
          setSelectedMetrics={setMetrics}
        />
        <SelectRange
          ranges={RANGES}
          range={range}
          defaultRange={RANGES[5]}
          setRange={handleRangeSelect}
          timezone={timezone}
        />
        <MuiTooltip title='fetch data' placement='top'>
          <span>
            <IconButton onClick={() => fetchChartData(range)}>
              <FontAwesomeIcon icon={['fal', 'cloud-download']} />
            </IconButton>
          </span>
        </MuiTooltip>
      </Stack>
      <WidgetLoader loading={loading || !chartData.length} />
      <ResponsiveContainer
        key='kpi-trends-chart-responsive-container'
        width='100%'
        height={400}>
        <LineChart
          width='100%'
          height={400}
          data={chartData}
          margin={{
            top: 5,
            right: 0,
            left: isMobile ? 0 : 5,
            bottom: 0,
          }}>
          <YAxis width={30} hide={isMobile} />
          <XAxis
            type='number'
            dataKey='timestamp'
            height={30}
            minTickGap={25}
            domain={[range.start.unix(), range.end.unix()]}
            tickCount={null}
            tickFormatter={(unixTime) =>
              dayjs(unixTime * 1000).format('MMM Do')
            }
          />
          <Tooltip content={<CustomTooltip />} />
          {map(metrics, (metric) => {
            const metricSettings = findMetricSettings(allMetrics, metric);
            return (
              <Line
                barSize={chartData.length === 1 ? 15 : null}
                key={metric}
                name={metric}
                type='monotone'
                dataKey={metric}
                stroke={theme.palette.veregy_colors[metricSettings.color]}
                dot={false}
                strokeWidth={2}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default KPITrendChart;
