import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import numeral from 'numeral';
import findIndex from 'lodash/findIndex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MuiTooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  Bar,
  BarChart,
  Brush,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { convertStringToUnix } from '../../../../../helpers/date';
import WebAPIClient, { errorResponseToastr } from '../../../../../api';
import { SOLAR_APP_ID } from '../../../../../store/solar';
import { openConfirmDialog } from '../../../../../store/dialogs';
import WidgetLoader from '../../../../../components/Loaders/WidgetLoader';
import ChartTooltip from '../Tooltip';

export const METHOD = {
  name: 'CSV',
  description: 'Backfill production data from a CSV file',
};

const processCsvData = (rawText, timezoneOffset) => {
  let response = [];
  const timestampIdx = 1;
  const kWhIdx = 5;
  let text = rawText;
  const rows = text.split('\n');
  const parsedData = rows.map((row) => row.split(','));

  // skip first three rows
  let prevKwh = null;
  for (let i = 3; i < parsedData.length; i++) {
    const row = parsedData[i];
    const kWh = Number(row[kWhIdx]);

    if (prevKwh === null) {
      prevKwh = kWh;
      continue;
    }
    const timestamp = convertStringToUnix(row[timestampIdx], timezoneOffset);
    if (!timestamp) {
      continue;
    }
    response.push({
      timestamp,
      value: (kWh - prevKwh) * 4,
    });
    prevKwh = kWh;
  }

  return response;
};

export default function CSVOptions(props) {
  const { meter, timezone, start, setStart, end, setEnd, setBackfilledData } =
    props;

  const dispatch = useDispatch();
  const theme = useTheme();
  const inputRef = useRef(null);

  const [hourAdjustment, setHourAdjustment] = useState(0);
  const [range, setRange] = useState({ start: 0, end: 0 });
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    let _data = [...csvData];
    _data = _data.map((d) => ({
      ...d,
      timestamp: d.timestamp + hourAdjustment * 3600,
    }));
    setChartData(_data);
  }, [csvData, hourAdjustment]);

  useEffect(() => {
    if (csvData.length > 0) {
      setRange({
        start: csvData[0].timestamp,
        end: csvData[csvData.length - 1].timestamp,
      });
    }
  }, [csvData]);

  const handleFileUpload = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const parsedData = processCsvData(event.target.result, timezone.offest);
        setCsvData(parsedData);
      } catch (err) {
        console.log('err: ', err);
      }
    };

    reader.readAsText(file);
    setLoading(false);
  };

  const backfill = () => {
    const startIdx = findIndex(chartData, (d) => d.timestamp === start);
    const endIdx = findIndex(chartData, (d) => d.timestamp === end);
    const selectedData = chartData.slice(startIdx, endIdx);

    const message = (
      <div>
        <p>Are you sure you want backfill this data?</p>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <strong>Meter:</strong>
              </td>
              <td>{meter.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    dispatch(
      openConfirmDialog({
        title: 'CSV Backfill',
        message,
        onConfirm: async () => {
          try {
            const payload = await new WebAPIClient(SOLAR_APP_ID).POST(
              `/resource/backfill/${meter.org_id}/${meter.meter_id}`,
              {
                backfill_method: 'csv',
                data: selectedData,
              }
            );
            setBackfilledData(payload);
            toastr.success('Success', `Rows backfilled: ${payload.length}`);
          } catch (error) {
            if (error?.code === 'ECONNABORTED') {
              toastr.error('Timeout Error', error?.message);
            } else {
              errorResponseToastr(error);
            }
          } finally {
            setLoading(false);
          }
        },
      })
    );
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleBrushChange = (indexes) => {
    const startTimestamp = csvData[indexes.startIndex]
      ? csvData[indexes.startIndex].timestamp
      : null;
    const endTimestamp = csvData[indexes.endIndex]
      ? csvData[indexes.endIndex].timestamp
      : null;

    setStart(startTimestamp);
    setEnd(endTimestamp);

    return indexes;
  };

  // TODO: Info Button about CSV assumptions
  // TODO: csv format error handler
  return (
    <Grid item xs={12}>
      <Card raised sx={{ py: 0.5, px: 0.5 }}>
        <CardHeader
          sx={{ pt: 1, pb: 0, mr: 1 }}
          title={METHOD.name}
          subheader={METHOD.description}
          action={
            <Stack
              direction='row'
              spacing={2}
              justifyContent='flex-end'
              alignItems='center'
              divider={
                <Divider orientation='vertical' flexItem sx={{ m: 1 }} />
              }>
              <TextField
                fullWidth
                label='Hour Adjustment'
                variant='standard'
                value={hourAdjustment}
                onChange={(e) => {
                  setHourAdjustment(e.target.value);
                }}
                type='number'
                sx={{ width: 100 }}
                InputProps={{ disableUnderline: true }}
                inputProps={{ style: { textAlign: 'center' } }}
              />
              <MuiTooltip title='upload CSV' placement='top'>
                <span>
                  <input
                    type='file'
                    accept='.csv'
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onChange={handleFileUpload}
                  />
                  <IconButton onClick={handleClick}>
                    <FontAwesomeIcon icon={['fal', 'upload']} />
                  </IconButton>
                </span>
              </MuiTooltip>
              <MuiTooltip title='backfill selected data' placement='top'>
                <span>
                  <IconButton
                    onClick={backfill}
                    disabled={!meter || csvData < 1}>
                    <FontAwesomeIcon icon={['fal', 'paper-plane']} />
                  </IconButton>
                </span>
              </MuiTooltip>
            </Stack>
          }
        />

        <WidgetLoader loading={loading} />
        {csvData.length === 0 ? (
          <Box
            sx={{
              height: 350,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography align='center'>Upload CSV Data</Typography>
          </Box>
        ) : (
          <ResponsiveContainer
            key='production-data-container'
            width='100%'
            height={350}
            style={{ marginBottom: 2 }}>
            <BarChart
              width='100%'
              height={350}
              data={chartData}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}>
              <YAxis
                width={75}
                domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
                tickFormatter={(val, _axis) => {
                  if (val <= 0) return '';
                  return (
                    numeral(Math.round(val).toPrecision(4)).format(
                      '0,0.[000]'
                    ) + ' kW'
                  );
                }}
              />
              <XAxis
                type='number'
                dataKey='timestamp'
                tickMargin={10}
                domain={[range?.start, range?.end]}
                tickFormatter={(unixTime) => {
                  return dayjs
                    .unix(unixTime)
                    .tz(timezone.zone)
                    .format('MMM Do');
                }}
              />
              <Tooltip content={<ChartTooltip timezone={timezone} />} />
              <Brush
                gap={1}
                dataKey='timestamp'
                height={30}
                stroke={theme.palette.secondary.main}
                onChange={handleBrushChange}
                tickFormatter={(unixTime) => {
                  return dayjs
                    .unix(unixTime)
                    .tz(timezone.zone)
                    .format('MMM Do');
                }}
              />
              <Bar
                barSize={10}
                type='monotone'
                dataKey='value'
                fill={theme.palette.primary.main}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Card>
    </Grid>
  );
}
