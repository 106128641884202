import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import dayjs from 'dayjs';
import numeral from 'numeral';

import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import each from 'lodash/each';
import filter from 'lodash/filter';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import _range from 'lodash/range';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import MuiTooltip from '@mui/material/Tooltip';

import {
  Area,
  Bar,
  Brush,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import WebAPIClient, { errorResponseToastr } from '../../../../../api';
import { SOLAR_APP_ID } from '../../../../../store/solar';
import { openConfirmDialog } from '../../../../../store/dialogs';
import SelectDatetime from '../../../../../components/Selectors/SelectDatetime';
import WidgetLoader from '../../../../../components/Loaders/WidgetLoader';
import InverterTooltip from '../Tooltip';

export const METHOD = {
  name: 'From Inverter Data',
  description: 'Estimate production data based on inverter data',
};

const getMeterValue = (obj) => {
  // Iterate over the keys in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key.startsWith('meter:')) {
      return obj[key]; // Return the value if key starts with "meter:"
    }
  }
  return null; // Return null if no such key exists
};

const getInverterSum = (obj) => {
  let sum = 0;

  // Iterate over the keys in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key.startsWith('inverter:')) {
      sum += obj[key]; // Add the value to the sum if the key starts with "inverter:"
    }
  }

  return sum; // Return the total sum
};

const compileChartData = (raw, range) => {
  let rawData = cloneDeep(raw);
  let timeseries = _range(range.start.unix(), range.end.unix() + 1, 900);
  timeseries = map(timeseries, (timestamp) => {
    let pointData = { timestamp };
    each(rawData, (deviceData) => {
      let datapoint = head(
        remove(
          get(deviceData, 'records', []),
          (record) => timestamp === record.timestamp
        )
      );

      if (datapoint && 'value' in datapoint) {
        pointData[deviceData.device_id] = datapoint.value;
      }
    });
    return pointData;
  });

  timeseries = map(timeseries, (datapoint) => {
    const meterValue = getMeterValue(datapoint);
    const inverterSum = getInverterSum(datapoint) * 0.95;
    if (inverterSum > 10 && inverterSum * 0.25 > meterValue) {
      return {
        ...datapoint,
        derivedProduction: inverterSum,
      };
    }
    return { ...datapoint };
  });
  return timeseries;
};

export default function InverterOptions(props) {
  const { meter, timezone, setStart, setEnd, setBackfilledData } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const allInverters = useSelector((state) => state.solar.inverters);

  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState({ start: null, end: null });
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [inverters, setInverters] = useState([]);

  useEffect(() => {
    if (meter !== null) {
      const lastMonth = dayjs().tz(timezone.name).subtract(1, 'month');
      setRange({
        start: lastMonth.startOf('month'),
        end: lastMonth.endOf('month'),
      });
    } else {
      setRange({ start: null, end: null });
    }
  }, [meter, timezone.name, setStart, setEnd]);

  useEffect(() => {
    if (allInverters.length && meter) {
      setInverters(
        allInverters.filter((inverter) => inverter.meter_id === meter.meter_id)
      );
    }
  }, [allInverters, meter]);

  useEffect(() => {
    if (!loading && rawData.length > 0 && range.start && range.end) {
      setData(compileChartData(rawData, range));
    }
  }, [rawData, range, loading]);

  const fetchChartData = () => {
    setLoading(true);

    Promise.all(
      map(concat(meter, inverters), async (device) => {
        const deviceId = get(device, `${device.type_}_id`);
        return {
          device_id: deviceId,
          records: await new WebAPIClient(SOLAR_APP_ID).GET(
            `/resource/timestream/${
              device.org_id
            }/${range.start.valueOf()}/${range.end.valueOf()}`,
            { deviceId }
          ),
        };
      })
    )
      .then((payloads) => {
        setRawData(payloads);
      })
      .catch((err) => {
        errorResponseToastr(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const backfill = () => {
    let backfillData = filter(data, (point) => 'derivedProduction' in point);
    backfillData = map(backfillData, (point) => ({
      timestamp: point.timestamp,
      value: point.derivedProduction,
    }));

    const message = (
      <div>
        <p>Are you sure you want backfill this data?</p>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <strong>Meter:</strong>
              </td>
              <td>{meter.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    dispatch(
      openConfirmDialog({
        title: 'Inverter Backfill',
        message,
        onConfirm: async () => {
          try {
            setLoading(true);
            const payload = await new WebAPIClient(SOLAR_APP_ID).POST(
              `/resource/backfill/${meter.org_id}/${meter.meter_id}`,
              {
                backfill_method: 'inverter',
                data: backfillData,
              }
            );
            setBackfilledData(payload);
            toastr.success('Success', `Rows backfilled: ${payload.length}`);
          } catch (error) {
            if (error?.code === 'ECONNABORTED') {
              toastr.error('Timeout Error', error?.message);
            } else {
              errorResponseToastr(error);
            }
          } finally {
            setLoading(false);
          }
        },
      })
    );
  };

  const handleBrushChange = (indexes) => {
    const startTimestamp = data[indexes.startIndex]
      ? data[indexes.startIndex].timestamp
      : null;
    const endTimestamp = data[indexes.endIndex]
      ? data[indexes.endIndex].timestamp
      : null;

    setStart(startTimestamp);
    setEnd(endTimestamp);

    return indexes;
  };

  return (
    <Grid item xs={12}>
      <Card raised sx={{ p: 0.5 }}>
        <CardHeader
          sx={{ pt: 1, pb: 0, mr: 1 }}
          title={METHOD.name}
          subheader={METHOD.description}
          action={
            <Stack
              direction='row'
              spacing={2}
              justifyContent='flex-end'
              alignItems='center'
              divider={
                <Divider orientation='vertical' flexItem sx={{ m: 1 }} />
              }>
              <SelectDatetime
                value={range.start}
                setValue={(value) => setRange((r) => ({ ...r, start: value }))}
                label='Start'
                timezone={timezone}
              />
              <SelectDatetime
                value={range.end}
                setValue={(value) => setRange((r) => ({ ...r, end: value }))}
                label='End'
                timezone={timezone}
              />
              <MuiTooltip title='fetch data' placement='top'>
                <span>
                  <IconButton onClick={fetchChartData} disabled={!meter}>
                    <FontAwesomeIcon icon={['fal', 'cloud-download']} />
                  </IconButton>
                </span>
              </MuiTooltip>
              <MuiTooltip title='backfill data' placement='top'>
                <span>
                  <IconButton
                    onClick={backfill}
                    disabled={!meter || !range.start || !range.end}>
                    <FontAwesomeIcon icon={['fal', 'paper-plane']} />
                  </IconButton>
                </span>
              </MuiTooltip>
            </Stack>
          }
        />
        <WidgetLoader loading={loading} />
        <ResponsiveContainer
          key='production-data-container'
          width='100%'
          height={350}
          style={{ marginBottom: 2 }}>
          <ComposedChart
            width='100%'
            height={350}
            data={data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}>
            <YAxis
              width={75}
              domain={[0, (dataMax) => Math.ceil(dataMax / 10) * 10]}
              tickFormatter={(val, _axis) => {
                if (val <= 0) return '';
                return (
                  numeral(Math.round(val).toPrecision(4)).format('0,0.[000]') +
                  ' kW'
                );
              }}
            />
            <XAxis
              type='number'
              dataKey='timestamp'
              tickMargin={10}
              domain={[range.start?.unix(), range.end?.unix()]}
              tickFormatter={(unixTime) => {
                return dayjs.unix(unixTime).tz(timezone.zone).format('MMM Do');
              }}
            />
            <Tooltip
              content={
                <InverterTooltip
                  timezone={timezone}
                  meter={meter}
                  inverters={inverters}
                />
              }
            />
            <Brush
              gap={1}
              dataKey='timestamp'
              height={30}
              stroke={theme.palette.secondary.main}
              onChange={handleBrushChange}
              tickFormatter={(unixTime) => {
                return dayjs.unix(unixTime).tz(timezone.zone).format('MMM Do');
              }}
            />
            <Bar
              barSize={10}
              key={`bar-${meter?.meter_id}`}
              type='monotone'
              dataKey={meter?.meter_id}
              fill={theme.palette.primary.main}
            />
            {map(sortBy(inverters, 'parent_index'), (inverter) => {
              return (
                <Area
                  dot={false}
                  stackId='area'
                  key={`line-${inverter.inverter_id}`}
                  dataKey={inverter.inverter_id}
                  stroke={theme.palette.veregy_colors.orange}
                  fill='transparent'
                  connectNulls
                />
              );
            })}
            <Bar
              barSize={10}
              key='bar-derivedProduction'
              type='monotone'
              dataKey='derivedProduction'
              fill={theme.palette.secondary.main}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card>
    </Grid>
  );
}
